import {BrowserModule} from '@angular/platform-browser';
import {APP_INITIALIZER, ErrorHandler, NgModule} from '@angular/core';

import {AppComponent} from './app.component';
import {JrzHttpModule, httpInterceptorProviders} from 'jrz-http';
import {JrzCommonModule, i18nService} from 'jrz-common';
import {MainModule} from '../main/main.module';
import {ReactiveFormsModule} from '@angular/forms';
import {catchError, first, mergeMap, take} from 'rxjs/operators';
import {i18nMessageService, ThemingService, CustomerDomainNameService, JrzAuthApiModule, TokenService, SsoService} from 'jrz-auth-api';
import {of} from 'rxjs';
import { CommonModule } from '@angular/common';

import * as Sentry from "@sentry/angular";
import { appRoutesNames } from '../app/app.routes.name';
import {ErrorComponent} from "../error/error.component";
import {AppRoutingModule} from "./app-routing.module";

@NgModule({
    declarations: [
        AppComponent,
        ErrorComponent
    ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        MainModule,
        JrzAuthApiModule,
        JrzHttpModule,
        JrzCommonModule,
        ReactiveFormsModule,
        CommonModule
    ],
    entryComponents: [
        AppComponent
    ],
    providers: [
        {
            provide: ErrorHandler,
            useValue: Sentry.createErrorHandler({
                showDialog: false,
            }),
        },
        {
            provide: APP_INITIALIZER,
            multi: true,
            useFactory: redirectProvider,
            deps: [CustomerDomainNameService, TokenService]
        },
        {
            provide: APP_INITIALIZER,
            multi: true,
            useFactory: i18nServiceProvider,
            deps: [i18nMessageService, i18nService]
        },
        {
            provide: APP_INITIALIZER,
            multi: true,
            useFactory: themeProvider,
            deps: [ThemingService]
        },{
            provide: APP_INITIALIZER,
            multi: true,
            useFactory: employeeSsoProvider,
            deps: [SsoService]
        }
        ],
    bootstrap: [AppComponent]
})
export class AppModule {
}

export function redirectProvider(customerDomainNameService: CustomerDomainNameService, tokenService: TokenService) {
    return () => {
        return new Promise<void>((resolve) => {
            if (window.location.pathname.includes(appRoutesNames.NEWSLETTER_OPT_IN_OUT)) {
                resolve(); //no redirect, just show the page
                return of(null);
            }

            customerDomainNameService.getCustomerDomainName()
                .pipe(take(1))
                .subscribe(customerDomainName => {
                    if (window.location.host.toLowerCase() !== customerDomainName.toLowerCase()) {
                        window.location.host = customerDomainName.toLowerCase();
                    }

                    tokenService.putToken().pipe(
                        catchError(() => of(null)),
                        mergeMap((response: any) => {
                            if (response && response.authToken) {
                                return of(response.authToken);
                            }
                            return of(null);
                        }))
                        .subscribe((authToken: any) => {
                            if (authToken) {
                                window.sessionStorage.setItem('authToken', authToken);

                                tokenService.getRedirectUrl()
                                .subscribe((response: any) => {
                                    const baseUrl = window.location.origin;
                                    window.location.href = baseUrl + response.url;
                                });
                            } else {
                                resolve();
                            }
                        });
                    });
                });
        };
}

export function i18nServiceProvider(i18nMessageService: i18nMessageService, i18nService: i18nService) {
    return () => {
        return new Promise<void>((resolve) => {
            i18nMessageService.getMessages()
                .pipe(first())
                .subscribe(messages => {
                    i18nService.setTranslations(messages);
                    resolve();
                });
        });
    };
}

export function themeProvider(themingService: ThemingService) {
    return () => {
        return new Promise<void>((resolve) => {
            themingService.getTheme().pipe(first())
                .subscribe(payload => {
                    themingService.setThemeName(payload.theme);
                    themingService.loadStyle(themingService.getThemeName());
                    resolve();
                });
        });
    };
}

export function employeeSsoProvider(ssoService: SsoService) {
    return () => {
        return new Promise<void>((resolve) => {
            ssoService.getSupportsEmployeeSso().pipe(first())
                .subscribe(payload => {
                    ssoService.setSupportsEmployeeSso(payload);
                    resolve();
                });
        });
    };
}

