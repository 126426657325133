<form [formGroup]="loginForm" (ngSubmit)="onSubmit()">
    <div *ngIf="preLoginText" [innerHTML]="preLoginText"></div>

    <div class="logo-container"><div class="logo"></div></div>

    <div *ngIf="showSsoPanel" id="form-container">
        <div class="sso-text-container">
            <p>
                {{i18nService.getMessage('login.sso.welcome')}}
            </p>
            <p>
                {{i18nService.getMessage('login.sso.make_a_choice')}}
            </p>
        </div>
        <ng-container *ngIf="!showEmployeeButtons">
            <button (click)="toggleEmployeeButtons()" class="btn btn-primary sso-button">
                <span>{{i18nService.getMessage('login.sso.button_employee')}}</span>
            </button>
            <button (click)="hideSSOPanel()" class="btn btn-primary sso-button">
                <span>{{i18nService.getMessage('login.sso.button_parent')}}</span>
            </button>
        </ng-container>
        <ng-container *ngIf="showEmployeeButtons">
            <button (click)="redirectToSSO()" class="btn btn-primary sso-button">
                <span>{{i18nService.getMessage('login.sso.button_employee_private')}}</span>
            </button>
            <button (click)="hideSSOPanel()" class="btn btn-primary sso-button">
                <span>{{i18nService.getMessage('login.sso.button_employee_group')}}</span>
            </button>
            <button id="employee-back-button"
                class="btn btn-primary employee-back-button"
                (click)="toggleEmployeeButtons()">
                {{i18nService.getMessage('web.general.label.back')}}
        </button>
        </ng-container>
    </div>

    <div *ngIf="!showSsoPanel" id="form-container">
        <div class="form-group" *ngIf="passwordResetSuccessful">
            <div>{{i18nService.getMessage('password.reset.succesfull')}}</div>
        </div>
        <div class="form-group">
            <label for="username">{{i18nService.getMessage('login.input.user')}}</label>
            <input id="login:username" type="text" formControlName="username" class="form-control" [ngClass]="{ 'is-invalid': submitted && form.username.errors }" autofocus/>
            <div *ngIf="submitted && form.username.errors" class="invalid-feedback">
                <div *ngIf="form.username.errors.required">{{i18nService.getMessage('login.input.user.required')}}</div>
            </div>
        </div>
        <div class="form-group">
            <label for="password">{{i18nService.getMessage('login.input.password')}}</label> <input id="login:password" type="password" formControlName="password" class="form-control" [ngClass]="{ 'is-invalid': submitted && form.password.errors }"/>
            <div *ngIf="submitted && form.password.errors" class="invalid-feedback">
                <div *ngIf="form.password.errors.required">{{i18nService.getMessage('login.input.password.required')}}</div>
            </div>
        </div>
        <loader *ngIf="isInitialLoading$ | async"></loader>
        <div class="form-check" *ngIf="form.childMinder">
            <input id="child-minder" type="checkbox" formControlName="childMinder" class="form-check-input"/>
            <label for="child-minder" class="form-check-label">{{i18nService.getMessage('login.input.login_as_childminder')}}</label>
        </div>
        <div class="form-group errors" *ngIf="!isLoggingIn">
            <div  class="text-danger" *ngFor="let error of errors">{{i18nService.getMessage(error.messageCode, error.parameters[0])}}</div>
        </div>
        <div class="form-group" *ngIf="form.captchaCode">
            <label>{{i18nService.getMessage('login.input.captcha')}}</label>
            <re-captcha formControlName="captchaCode"
                        required>
            </re-captcha>
        </div>
        <div class="form-group">
            <button id="login-button" [disabled]="isLoggingIn || initialLoading" class="btn btn-primary">
                <span *ngIf="isLoggingIn" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                <span *ngIf="!isLoggingIn">{{i18nService.getMessage('login.action.login')}}</span>
            </button>
            <div *ngIf="ssoForEmployee"
                    id="back-button"
                    class="btn btn-primary back-button"
                    (click)="showSSOPanel()">
                {{i18nService.getMessage('web.general.label.back')}}
            </div>
        </div>
        <div>
            <a [routerLink]="resetPasswordLink" (click)="this.credentialsService.currentUsername = form.username.value"> {{i18nService.getMessage('login.action.forgot.password')}}</a>
        </div>
        <div *ngIf="postLoginText" [innerHTML]="postLoginText"></div>
    </div>
</form>
